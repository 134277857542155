import React from 'react'
import { Link, graphql } from 'gatsby'

import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

import GraphQLErrorList from '../components/graphql-error-list'
import BlockContent from '../components/block-content'
import SEO from '../components/seo'

import Layout from '../containers/layout'

export const query = graphql`
  query WorkPageQuery {
    work: sanityWorkPage(_id: { regex: "/(drafts.|)workPage/" }) {
      title
      title1
      body
      slide1Image {
        asset {
          _id
        }
      }
    }

    nodes: allSanityWork(sort: { fields: [title], order: ASC }) {
      edges {
        node {
          id
          mainImage {
            asset {
              _id
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const ParagraphClass = 'dib relative w-100 measure-wide dark-gray fw4 mb4'
const HeadingClass = 'dib relative w-100 measure f2 fw5 mv3'

const WorkPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const work = data && data.work
  const workNodes =
    data && data.nodes && mapEdgesToNodes(data.nodes).filter(filterOutDocsWithoutSlugs)

  if (!work) {
    throw new Error(
      'Missing "Work" page data. Open the studio at http://localhost:3333 and add "Work" page data and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO title={work.title} />
      <article>
        {/* Work */}
        <section className="dib relative w-100 bg-white black lh-copy f4" id="work">
          <div className="db center mw8 ph4 pt6 pb4">
            <h1 className={HeadingClass}>{work.title1}</h1>
            <p className={ParagraphClass}>{work.body}</p>
          </div>
          {work.slide1Image && work.slide1Image.asset && work.slide1Image.asset._id ? (
            <div
              className="dib relative w-100 pb2"
              style={{
                background: `url(${imageUrlFor(
                  buildImageObj(work.slide1Image)
                )}) no-repeat center center / cover`
              }}
            >
              &nbsp;
            </div>
          ) : null}
        </section>
        {/* Content */}
        <section className="dib relative bg-white w-100 black lh-copy f4 mb0 mb4-l" id="content">
          <div className="db center mw8 ph4 pv4">
            <div className="row">
              {workNodes.map((object, index) => (
                <div className="col-xs-12 col-md-4" key={`work-${index}`}>
                  <Link
                    className="dib relative w-100 mb4 link dim"
                    to={`/work/${object.slug.current}`}
                  >
                    {object.mainImage && object.mainImage.asset && object.mainImage.asset._id ? (
                      <div
                        className="dib relative w-100 fit br3 mb3 lawyer-pic"
                        style={{
                          background: `url(${imageUrlFor(
                            buildImageObj(object.mainImage)
                          )}) no-repeat center center / cover`
                        }}
                      >
                        &nbsp;
                      </div>
                    ) : (
                      <div
                        className="dib relative w-100 fit br3 mb3 lawyer-pic"
                        style={{
                          background: `url('/img/empty-work.png') no-repeat center center / cover`
                        }}
                      >
                        &nbsp;
                      </div>
                    )}
                    <div className="dib relative w-100">
                      <strong className="dib relative w-100 f4 b black mb2">{object.title}</strong>
                      {object._rawExcerpt ? (
                        <div className="dib relative w-100 measure-wide dark-gray fw4 f5 mv0 hidden-content">
                          <BlockContent blocks={object._rawExcerpt} />
                        </div>
                      ) : null}
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </section>
      </article>
    </Layout>
  )
}

export default WorkPage
